<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schoolPages.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button @click="addPage()">{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <table class="table table-bordered" id="list" v-if="!showForm">
              <thead class="thead-light">
              <tr>
                <th class="text-left">{{ $t("schoolPages.list.type") }}</th>
                <th class="text-left">{{ $t("schoolPages.list.date") }}</th>
                <th class="text-left">{{ $t("schoolPages.list.titleKg") }}</th>
                <th class="text-left">{{ $t("schoolPages.list.titleRu") }}</th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in pages" :key="item.id">
<!--                <td>{{ item.type }}</td>-->
                <td>{{ showType(item.type) }}</td>
                <td>{{ item.date }}</td>
                <td>{{ item.titleKg }}</td>
                <td>{{ item.titleRu }}</td>
                <td>
                  <b-button @click="editPage(item)" variant="primary">
                    {{ $t("common.edit") }}
                  </b-button>  
                  <b-button @click="deletePage(item)" 
                            variant="danger"
                  >
                    {{ $t("common.delete") }}
                  </b-button>
                </td>
              </tr>
              </tbody>
            </table>
            <div id="form" v-if="showForm">
              <b-form id="pagesForm" enctype="multipart/form-data">
                <input type="hidden" name="id" v-model="form.id">
                <input type="hidden" name="image" v-model="form.image">
                <b-form-group>
                  <template v-slot:label>
                    {{ $t("schoolPages.list.type") }}
                  </template>
                  <b-form-select
                      v-model="form.type"
                      :options="pagesTypes"
                      :state="modelState.type"
                      name="type"
                  ></b-form-select>
                </b-form-group>
                
                <img v-if="form.type=='news' && form.image != ''" style="width: 200px" :src="imageUrlBase + form.image">
                
                <b-form-group v-if="form.type=='news'">
                  <template v-slot:label>
                    {{ $t("schoolPages.list.mainImage") }}
                  </template>
                  <input type="file" @change="changeMainImage" ref="mainImage">
                </b-form-group>
                <b-tabs>
                  <b-tab>
                    <template v-slot:title>
                      Кыргызча
                    </template>
                    <b-form-group>
                      <template v-slot:label>
                        {{ $t("schoolPages.list.titleKg") }}
                      </template>
                      <b-form-input
                          v-model="form.titleKg"
                          name="titleKg"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                      <template v-slot:label>
                        {{ $t("schoolPages.list.contentKg") }}
                      </template>
                      <ckeditor :editor="editor" :editor-url="editorUrl" v-model.lazy="form.contentKg" :config="editorConfig"
                                id="editorKg"
                                tag-name="textarea"
                                name="contentKg"
                                @namespaceloaded="onNamespaceLoaded"
                       ></ckeditor>
                    </b-form-group>
                  </b-tab>
                  <b-tab>
                    <template v-slot:title>
                      Русский
                    </template>
                    <b-form-group>
                      <template v-slot:label>
                        {{ $t("schoolPages.list.titleRu") }}
                      </template>
                      <b-form-input
                          v-model="form.titleRu"
                          name="titleRu"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group>
                      <template v-slot:label>
                        {{ $t("schoolPages.list.contentRu") }}
                      </template>
                      <ckeditor :editor="editor2" :editor-url="editorUrl" v-model.lazy="form.contentRu" :config="editorConfig"
                                id="editorRu"
                                tag-name="textarea"
                                name="contentRu"
                               
                      >
                        
                      </ckeditor>
                    </b-form-group>
                  </b-tab>
                </b-tabs>
                
              </b-form>              
              <div>
                <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
              </div>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import ClassicEditor from 'ckeditor4-vue';
import JwtService from "@/common/jwt.service";
//import Image from '@ckeditor/ckeditor5-easy-image';
//import { ImageResizeEditing, ImageResizeHandles } from '@ckeditor/ckeditor5-image';
/*import { Bold, Italic } from '@ckeditor/ckeditor5-basic-styles';*/
//import CKFinder from '@ckeditor/ckeditor5-ckfinder';



export default {
  name: "schoolPages",
  components: {
    KTPortlet
  },
  data() {
    return {
      pages: [],
      files: null,
      dialog: true,
      editorUrl: "https://cdn.ckeditor.com/4.22.1/standard/ckeditor.js",
      editor: ClassicEditor,
      editor2: ClassicEditor,
      editorData: '<p></p>',
      editorConfig: {
        /*plugins: [
          CKFinder
        ],*/
        /*toolbar: [ 'uploadImage', /!* ... *!/ ],*/
        /*cloudServices: {
          tokenUrl: '/api/security/verify/',
          uploadUrl: '/asasas'
        }*/
        //filebrowserBrowseUrl: 'http://97.school.devel/1',
        filebrowserUploadUrl: '/api/schoolPagesAdmin/fileUpload',
        clipboard_handleImages: false,
        //filebrowserUploadMethod: 'form', 
        extraPlugins : 'uploadimage',
        configAllowedTypes: ['Image', 'Flash', 'File', 'Media'],
        fileUploadRequest: function (){
          alert('asas');
        },
        versionCheck : false
      },
      test:"",
      form: {
        id: 0,
        schoolId: "",
        type: "",
        titleKg: "",
        titleRu: "",
        contentKg: "",
        contentRu: ""
      },
      showForm: false,
      pagesTypes: [
        {value: "school", text: "Школа"},
        {value: "parents", text: "Родителям"},
        {value: "students", text: "Ученикам"},
        {value: "accreditation", text: "Аккредитация"},
        {value: "news", text: "Новости"},
      ],
      pagesTypesVal:{},
      modelState: {},
      imageUrlBase: '',
      mainImageUploadUrl: '/api/schoolPagesAdmin/fileUpload'
    };
  },
  mounted() {

    for ( let i in this.pagesTypes) {
      let pt = this.pagesTypes[i];

      this.pagesTypesVal[pt.value] = pt.text;
    }
    this.reloadData();
  },
  methods: {
    onNamespaceLoaded( CKEDITOR ) {
      CKEDITOR.on( 'instanceReady', function( evt ) {
        let editor = evt.editor;
        let element = editor.element.$;

        evt.editor.name1 = "page-" + window.editeId + "-" + element.parentNode.id;
        
        evt.editor.on( 'fileUploadRequest', function( evt1 ) {

          let fileLoader = evt1.data.fileLoader;
          let xhr = evt1.data.fileLoader.xhr;
          xhr.open('POST', fileLoader.uploadUrl);
          xhr.setRequestHeader( 'Authorization', `Bearer ${JwtService.getToken()}` );
          
          const formData = new FormData();
          formData.append("file", fileLoader.file);
          
          xhr.send( formData );
          
          evt1.stop();
        }, null, null, 4 );
      } );
    },
    reloadData() {

      
      ApiService.querySecured("schoolPagesAdmin/schoolPages")
          .then(({ data }) => {
            this.pages = data.list;
            this.imageUrlBase = data.imageUrlBase;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    showType(type){
      return this.pagesTypesVal[type];
    },
    editPage(page) {
      
      window.editeId = page.id;
      this.form = page;
      this.showForm = true;
    },
    deletePage(page) {
      if(confirm("Удалить страницу?")){
        let $this = this;

        ApiService.postSecured("schoolPagesAdmin/deletePage", {id:page.id})
            .then(function () {
              $this.reloadData();
            })
            .catch(({response}) => {
              console.log(response);
              $this.reloadData();
            });
      }
    },
    addPage() {
      window.editeId = Math.random();
      this.form = {
        id: 0
      };
      this.showForm = true;
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      for (let ii in window.CKEDITOR.instances){
        window.CKEDITOR.instances[ii].destroy();
      }

      this.showForm = false;
      
      this.form = {
        id: 0
      };      
      
      this.showForm = false;
      
    },
    uploadFile() {
      this.files = this.$refs.file.files[0];
    },
    changeMainImage() {
      
      let $this = this;
      
      let file = this.$refs.mainImage.files[0];
      let xhr = new XMLHttpRequest();

      xhr.open('POST', this.mainImageUploadUrl);
      xhr.setRequestHeader( 'Authorization', `Bearer ${JwtService.getToken()}` );
      xhr.responseType = 'json';
      
      const formData = new FormData();
      formData.append("file", file);

      xhr.onload = function() {
        let response = xhr.response;
        console.log(response);
        $this.form.image = response["fileName"];
      };
      
      xhr.send( formData );
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;


      let form = document.getElementById("pagesForm");

      const formData = new FormData(form);
      
      for (let ii in window.CKEDITOR.instances){
        let name = window.CKEDITOR.instances[ii].name1;
        if(name === "page-" + window.editeId + "-editorKg"){
          formData.set('contentKg', window.CKEDITOR.instances[ii].getData());
        }else{
          formData.set('contentRu', window.CKEDITOR.instances[ii].getData());
        }
      }
      

      ApiService.importSecuredFormDate("schoolPagesAdmin/savePage", formData)
          .then(function() {
            $this.showForm = false;
            
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    onSubmit1(evt) {
      evt.preventDefault();
      let $this = this;

      for (let ii in window.CKEDITOR.instances){
        let name = window.CKEDITOR.instances[ii].name1;
        if(name === "page-" + window.editeId + "-editorKg"){
          this.form.contentKg = window.CKEDITOR.instances[ii].getData();
        }
      }

      ApiService.importSecured("schoolPagesAdmin/savePage", this.files, this.form)
          .then(function() {
            $this.showForm = false;

            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({ response }) => {
            console.log(response);
            $this.reloadData();
          });
    },
    cleanModel() {
           
      this.form = {
        id: 0
      };
    }
  }
};
</script>
